<template>
  <div class="LayoutSettingsMain">
    <div>
      <p class="pageSettingsTitle">Layout</p>
      <p class="pageSettingHeader">
        * Selecting "Update Layout" will affect all quiz pages
      </p>
    </div>

    <div class="mt-1">
      <div class="row">
        <div class="col-12">
          <p class="pageSettingHeader">Page size:</p>
        </div>
        <div class="col-6 d-flex align-items-center">
          <span style="color: #d2d8e0" class="mr-2">W</span>
          <div class="layoutInput flex-grow-1">
            <input
              type="number"
              v-model="layoutValues.width"
              class="w-100"
              placeholder="Width"
            />
          </div>
        </div>
        <div class="col-6 d-flex align-items-center">
          <span style="color: #d2d8e0" class="mr-2">H</span>
          <div class="layoutInput flex-grow-1">
            <input
              type="number"
              class="w-100"
              v-model="layoutValues.height"
              placeholder="Height"
            />
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <p class="pageSettingHeader">Page Paddings ({{ getEditorPageView == 'desktop' ? 'Desktop' : 'Mobile' }}):</p>
        </div>
        <div class="col-6 d-flex align-items-center mb-2">
          <svg
            width="15"
            height="16"
            viewBox="0 0 15 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 15H15V16H0V15ZM1 1H0V0H1V1ZM15 0V1H14V0H15ZM4 1H2V0H4V1ZM7 1H5V0H7V1ZM10 1H8V0H10V1ZM13 1H11V0H13V1ZM5.35156 6.35156L4.64844 5.64844L7.5 2.79688L10.3516 5.64844L9.64844 6.35156L8 4.71094V13H7V4.71094L5.35156 6.35156Z"
              fill="#B3AFB6"
            />
          </svg>
          <div class="ml-2 layoutInput flex-grow-1">
            <input
              type="number"
              class="w-100"
              v-model="layoutValues.paddingTop"
              placeholder="Top"
            />
          </div>
        </div>
        <div class="col-6 d-flex align-items-center mb-2">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_1_1259)">
              <path
                d="M16 1L1 0.999999L1 -1.31134e-06L16 0L16 1ZM15 15L16 15L16 16L15 16L15 15ZM1 16L1 15L2 15L2 16L1 16ZM12 15L14 15L14 16L12 16L12 15ZM9 15L11 15L11 16L9 16L9 15ZM6 15L8 15L8 16L6 16L6 15ZM3 15L5 15L5 16L3 16L3 15ZM10.6484 9.64844L11.3516 10.3516L8.5 13.2031L5.64844 10.3516L6.35156 9.64844L8 11.2891L8 3L9 3L9 11.2891L10.6484 9.64844Z"
                fill="#B3AFB6"
              />
            </g>
            <defs>
              <clipPath id="clip0_1_1259">
                <rect
                  width="16"
                  height="16"
                  fill="white"
                  transform="translate(16 16) rotate(-180)"
                />
              </clipPath>
            </defs>
          </svg>

          <div class="ml-2 layoutInput flex-grow-1">
            <input
              type="number"
              class="w-100"
              v-model="layoutValues.paddingBottom"
              placeholder="Bottom"
            />
          </div>
        </div>
        <div class="col-6 d-flex align-items-center mb-2">
          <svg
            width="16"
            height="15"
            viewBox="0 0 16 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 15L15 -6.55671e-07L16 -6.99382e-07L16 15L15 15ZM1 14L1 15L0 15L-4.37114e-08 14L1 14ZM-6.55671e-07 0L0.999999 -4.37114e-08L0.999999 1L-6.11959e-07 1L-6.55671e-07 0ZM1 11L1 13L-8.74228e-08 13L-1.74846e-07 11L1 11ZM1 8L1 10L-2.18557e-07 10L-3.0598e-07 8L1 8ZM1 5L1 7L-3.49691e-07 7L-4.37114e-07 5L1 5ZM0.999999 2L1 4L-4.80825e-07 4L-5.68248e-07 2L0.999999 2ZM6.35156 9.64844L5.64844 10.3516L2.79687 7.5L5.64844 4.64844L6.35156 5.35156L4.71094 7L13 7L13 8L4.71094 8L6.35156 9.64844Z"
              fill="#B3AFB6"
            />
          </svg>

          <div class="layoutInput ml-2 flex-grow-1">
            <input
              type="number"
              v-model="layoutValues.paddingLeft"
              placeholder="Left"
              class="w-100"
            />
          </div>
        </div>
        <div class="col-6 d-flex align-items-center mb-2">
          <svg
            width="16"
            height="15"
            viewBox="0 0 16 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 -6.55671e-07L0.999999 15L-6.55671e-07 15L0 -6.99382e-07L1 -6.55671e-07ZM15 1L15 -4.37114e-08L16 0L16 1L15 1ZM16 15L15 15L15 14L16 14L16 15ZM15 4L15 2L16 2L16 4L15 4ZM15 7L15 5L16 5L16 7L15 7ZM15 10L15 8L16 8L16 10L15 10ZM15 13L15 11L16 11L16 13L15 13ZM9.64844 5.35156L10.3516 4.64844L13.2031 7.5L10.3516 10.3516L9.64844 9.64844L11.2891 8L3 8L3 7L11.2891 7L9.64844 5.35156Z"
              fill="#B3AFB6"
            />
          </svg>
          <div class="layoutInput ml-2 flex-grow-1">
            <input
              type="number"
              class="w-100"
              v-model="layoutValues.paddingRight"
              placeholder="Right"
            />
          </div>
        </div>

        <div class="col-12 mt-2">
          <Button @click="updateLayout"> Update Layout</Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Button from "../../../../Dashboard/Layout/Button.vue";
export default {
  components: {
    Button,
  },
  data() {
    return {
      isupdateLayoutLoading: false,
      layoutValues: {
        height: 540,
        width: 850,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
      },
    };
  },
  computed: {
    ...mapGetters(["getTotalPages","getEditorPageView"]),
    getAllPages() {
      return this.getTotalPages;
    },
  },
  methods: {
    updateLayout() {
      const allPages = [...this.getAllPages];
      const {
        height,
        width,
        paddingTop,
        paddingBottom,
        paddingLeft,
        paddingRight,
      } = this.layoutValues;

      try {
        this.isupdateLayoutLoading = true;

        allPages.forEach((item) => {
          this.$set(item.pageDesign.pageStyles, "height", height);
          this.$set(item.pageDesign.pageStyles, "width", width);
            if(this.getEditorPageView == 'desktop'){
              this.$set(item.pageDesign.pageStyles, "paddingTop", paddingTop);
          this.$set(item.pageDesign.pageStyles, "paddingBottom", paddingBottom);
          this.$set(item.pageDesign.pageStyles, "paddingRight", paddingRight);
          this.$set(item.pageDesign.pageStyles, "paddingLeft", paddingLeft);
            } else {
              this.$set(item.pageDesign.pageStyles, "mobilePaddingTop", paddingTop);
          this.$set(item.pageDesign.pageStyles, "mobilePaddingBottom", paddingBottom);
          this.$set(item.pageDesign.pageStyles, "mobilePaddingRight", paddingRight);
          this.$set(item.pageDesign.pageStyles, "mobilePaddingLeft", paddingLeft);
            }
        });
        this.$nextTick(() => {
          this.$toasted.show("Layout update successfully", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        });
      } catch (error) {
        console.log("Error Occurred", error);
        this.$toasted.show("Error Occurred while using Scheme", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
      } finally {
        this.isupdateLayoutLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.layoutInput {
  padding: 2px;
  width: 80%;
  border-radius: 6px;
  border: 0.8px solid #d2d8e0;
}

.layoutInput input {
  padding-left: 2px;
  border: 0;
  outline: 0;
  color: #73738d;
}
.layoutInput input:focus {
  border: 0;
  outline: 0;
}
</style>
